import { useState } from "react";
import "./Page1.scss";
import {
  BsDiscord,
  BsFacebook,
  BsTwitter,
  BsYoutube,
  BsReddit,
  BsShareFill,
  BsInstagram,
  BsTiktok,
} from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";

const urlOculus = "https://www.oculus.com/experiences/quest/6494816893945076/";
const urlSteam = "https://store.steampowered.com/app/2474850/Wisdom_Watcher/";

const App = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 600px)",
  });
  const [showVideo, setShowVideo] = useState(false);
  const handleOpenPlay = () => {
    setShowVideo(true);
  };
  const handleClosePlay = () => {
    setShowVideo(false);
  };

  const handleSocial = (url) => {
    window.open(url);
  };

  const handleShare = () => {
    const shareData = {
      title: "Wisdom Watcher",
      text: "Get the Official News Here",
      url: "https://www.wisdomwatcher.com/",
    };
    if (navigator.share && navigator.canShare(shareData)) {
      navigator.share(shareData);
    } else {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(shareData.url);
        toast.success("Copied to clipboard");
      }
    }
  };

  return (
    <div {...props} className="viewport">
      <video
        className="fullscreen"
        src={
          isDesktopOrLaptop
            ? "assets/Video_SiteWW_FullHD.mp4"
            : "assets/Video_SiteWW_FullHDVertical.mp4"
        }
        playsInline
        autoPlay
        muted
        loop
      ></video>

      <img className="logo" src="assets/WW_LogoWhite.png" alt="" />

      <div className="right_bar">
        <div className="right_bar_content">
          <div style={{ alignSelf: "stretch" }}></div>
          <BsShareFill className="icon" onClick={handleShare} />
          {isDesktopOrLaptop && (
            <>
              <p style={{ marginTop: "60px" }}>Follow</p>
              <div className="line" />
            </>
          )}
          <BsDiscord
            className="icon"
            onClick={() => handleSocial("https://discord.gg/bCVKz57hMe")}
          />
          <BsFacebook
            className="icon"
            onClick={() =>
              handleSocial("https://www.facebook.com/spacewhalegamestudio/")
            }
          />
          <BsTwitter
            className="icon"
            onClick={() => handleSocial("https://twitter.com/SpaceWhale_Game")}
          />
          <BsYoutube
            className="icon"
            onClick={() =>
              handleSocial("https://www.youtube.com/@SpaceWhaleStudio")
            }
          />
          <BsReddit
            className="icon"
            onClick={() =>
              handleSocial("https://www.reddit.com/r/WisdomWatcher/")
            }
          />
          <BsInstagram
            className="icon"
            onClick={() =>
              handleSocial("https://www.instagram.com/spacewhalevr/")
            }
          />
          <BsTiktok
            className="icon"
            onClick={() =>
              handleSocial("https://www.tiktok.com/@spacewhalestudio")
            }
          />
        </div>
      </div>
      {isDesktopOrLaptop && (
        <>
          <div className="bottom_bar">
            <div className="button_container">
              <div className="button" onClick={() => handleSocial(urlOculus)}>
                <img
                  className="image"
                  src="assets/button_OculusAppLab.png"
                  alt=""
                ></img>
                {/* <div className="sub">Free Demo</div> */}
              </div>
              <div className="button" onClick={() => handleSocial(urlSteam)}>
                <img
                  className="image"
                  src="assets/button_steamVR.png"
                  alt=""
                ></img>
                {/* <div className="sub">Free Demo</div> */}
              </div>
            </div>
          </div>
          {/* <div className="bottom_left_bar">
            <p>Scroll Down</p>
            <div className="line" />
          </div> */}
        </>
      )}
      {!isDesktopOrLaptop && (
        <>
          <div className="mobile_button_container">
            <div className="button" onClick={() => handleSocial(urlOculus)}>
              <img
                style={{ width: "160px" }}
                className="image"
                src="assets/button_OculusAppLab.png"
                alt=""
              ></img>
              {/* <div className="sub">Free Demo</div> */}
            </div>
            <div style={{ width: "20px" }} />
            <div className="button" onClick={() => handleSocial(urlSteam)}>
              <img
                style={{ width: "160px" }}
                className="image"
                src="assets/button_steamVR.png"
                alt=""
              ></img>
              {/* <div className="sub">Free Demo</div> */}
            </div>
          </div>
          {/* <IoIosArrowDown className="bottom_arrow" /> */}
        </>
      )}
      <div
        className="play_button"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/assets/play_button.png')`,
        }}
        onClick={handleOpenPlay}
      ></div>
      {/* <div
        className="leaderboards_button"
        onClick={() => (state.showLeaderboard = true)}
      ></div> */}

      {showVideo && (
        <>
          <div className="youtube_container" onClick={handleClosePlay} />
          <iframe
            src="https://www.youtube.com/embed/myP85hhquTk"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className="youtube_content"
          ></iframe>
        </>
      )}
    </div>
  );
};

export default App;
