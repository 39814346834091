import { useSnapshot } from "valtio";
import "./Leaderboards2.scss";
import { state } from "store";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";

const parseName = (value) => {
  return value.substring(0, value.indexOf("#"));
};

const App = () => {
  const snap = useSnapshot(state);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    var d = document.getElementById("l1");
    d.scrollIntoView();
  }, []);

  return (
    <>
      <div className="leaderboard">
        {!isMobile && (
          <>
            <div className="bottom_left_bar">
              <p>Scroll Down</p>
              <div className="line" />
            </div>
          </>
        )}
        <div className="rows">
          <div>
            <p>Rank</p>
            <p>Username</p>
            <p>Score</p>
          </div>
          {snap.leaderboard !== undefined &&
            snap.leaderboard.map((leadRow, index) => (
              <div key={index} className="row">
                <p>{leadRow.rank + 1}</p>
                <p>{parseName(leadRow.playerName)}</p>
                <p>{leadRow.score}</p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default App;
