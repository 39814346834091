import { useSnapshot } from "valtio";
import "./Leaderboards.scss";
import { useCallback, useEffect, useState } from "react";
import { state } from "store";
import { useMediaQuery } from "react-responsive";

const projectId = "91300acf-17fb-4262-9317-9173028693a8";

// const exampleA = `curl -H "Authorization: Basic ZTcwNzYyZmItMjAyNi00ODRlLThmMWEtZDExN2RmNzZjZjdkOjVhWFZ1aU5YQ0VwNUNQQ09zOGJVZnBzOHZXQ25tNlk5" https://services.api.unity.com/`;
// const exampleUA = `curl -XPOST -H "ProjectId: 91300acf-17fb-4262-9317-9173028693a8" https://player-auth.services.api.unity.com/v1/authentication/anonymous`;
// const example = `curl -H "Authorization: Basic ZTcwNzYyZmItMjAyNi00ODRlLThmMWEtZDExN2RmNzZjZjdkOjVhWFZ1aU5YQ0VwNUNQQ09zOGJVZnBzOHZXQ25tNlk5" https://leaderboards.services.api.unity.com/v1/projects/91300acf-17fb-4262-9317-9173028693a8/leaderboards/Monthly_Horde_Watchers/scores`;
// const exampleU = `curl -H "Authorization: Bearer " https://leaderboards.services.api.unity.com/v1/projects/91300acf-17fb-4262-9317-9173028693a8/leaderboards/Monthly_Horde_Watchers/scores`;

const leaderboardURL = `https://leaderboards.services.api.unity.com/v1/projects/${projectId}/leaderboards`;
const leaderboards = [
  { id: "Daily_Horde_Watchers", title: "DAILY" },
  { id: "Monthly_Horde_Watchers", title: "MONTHLY" },
  { id: "Seasonal_Horde_Watchers", title: "SEASON" },
];
const max = 300;
const min = 30;

const parseName = (value) => {
  return value.substring(0, value.indexOf("#"));
};

const App = () => {
  const [leaderboard, setLeaderboard] = useState(0);
  const snap = useSnapshot(state);
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const handleAuth = useCallback(async () => {
    var response = await fetch(
      `https://player-auth.services.api.unity.com/v1/authentication/anonymous`,
      {
        headers: {
          ProjectId: projectId,
        },
        method: "POST",
        mode: "cors",
      }
    );
    const data = await response.json();
    console.log(data);
    state.unityAccessToken = data.idToken;
    state.unityUserId = data.userId;
  }, []);

  const handleGetLeaderboard = useCallback(async () => {
    var response = await fetch(
      `${leaderboardURL}/${leaderboards[leaderboard].id}/scores?limit=50`,
      {
        headers: {
          Authorization: `Bearer ${snap.unityAccessToken}`,
        },
        method: "GET",
        mode: "cors",
      }
    );
    const data = await response.json();
    console.log(data);
    if (data.results.length < 20) {
      // aggiungo uno score
      const randomScore = Math.floor(Math.random() * (max - min + 1)) + min;
      leaderboards.forEach((l) => {
        fetch(`${leaderboardURL}/${l.id}/scores/players/${snap.unityUserId}`, {
          headers: {
            Authorization: `Bearer ${snap.unityAccessToken}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ score: randomScore }),
        }).then((response) =>
          response.json().then((value) => console.log(value))
        );
      });
    }
    state.leaderboard = data.results;
  }, [snap.unityAccessToken, snap.unityUserId, leaderboard]);

  const handlePreviusType = () => {
    setLeaderboard(
      leaderboard === 0 ? leaderboards.length - 1 : leaderboard - 1
    );
    state.leaderboard = undefined;
  };

  const handleNextType = () => {
    setLeaderboard(
      leaderboard === leaderboards.length - 1 ? 0 : leaderboard + 1
    );
    state.leaderboard = undefined;
  };

  useEffect(() => {
    if (!snap.unityAccessToken) {
      handleAuth();
    } else if (snap.leaderboard === undefined) {
      handleGetLeaderboard();
    }
  }, [snap, handleAuth, handleGetLeaderboard]);

  return (
    <>
      <div className="leaderboard">
        <img
          className="logo"
          src="assets/WW_LogoWhite.png"
          alt=""
          onClick={() => (state.showLeaderboard = false)}
        />
        <img
          className="season"
          src={
            isMobile
              ? "assets/Leaderboard_Season01M.png"
              : "assets/Leaderboard_Season01.png"
          }
          alt=""
        />

        {isMobile ? null : (
          <>
            <div className="bottom_left_bar">
              <p>Scroll Down</p>
              <div className="line" />
            </div>
          </>
        )}
        <div className="header">
          <div className="left_arrow"></div>
          <div className="title">
            <div className="name">
              <p>MONSTER</p>
              <p>CAVE</p>
            </div>
            <div className="type">
              <div className="left_arrow" onClick={handlePreviusType}></div>
              <p>{leaderboards[leaderboard].title}</p>
              <div className="right_arrow" onClick={handleNextType}></div>
            </div>
          </div>
          <div className="right_arrow"></div>
        </div>

        {isMobile ? (
          <>
            <div className="rows">
              <div>
                <p>Rank</p>
                <p>Username</p>
                <p>Score</p>
              </div>
              {snap.leaderboard !== undefined &&
                snap.leaderboard.map((leadRow, index) => (
                  <div key={index} className="row">
                    <p>{leadRow.rank + 1}</p>
                    <p>{parseName(leadRow.playerName)}</p>
                    <p>{leadRow.score}</p>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <div className="podium">
            {snap.leaderboard !== undefined && snap.leaderboard.length > 0 && (
              <div>
                <p>{parseName(snap.leaderboard[0].playerName)}</p>
                <p>{snap.leaderboard[0].score}</p>
              </div>
            )}
            {snap.leaderboard !== undefined && snap.leaderboard.length > 1 && (
              <div>
                <p>{parseName(snap.leaderboard[1].playerName)}</p>
                <p>{snap.leaderboard[1].score}</p>
              </div>
            )}
            {snap.leaderboard !== undefined && snap.leaderboard.length > 2 && (
              <div>
                <p>{parseName(snap.leaderboard[2].playerName)}</p>
                <p>{snap.leaderboard[2].score}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default App;
