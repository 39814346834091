import "./PageFooter.scss";

export const FooterContent = () => {
  return (
    <>
      {/* <a target="_blank" rel="noreferrer" href="/Privacy Policy.pdf"> */}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://space-whale.com/privacy-policy/"
      >
        Privacy Policy
      </a>
      {/* <p>Term of Service</p> */}
      {/* <a target="_blank" rel="noreferrer" href="https://www.space-whale.com/">
        About us
      </a> */}
      {/* <a
        target="_blank"
        rel="noreferrer"
        href="https://www.digitalmosaik.com/contact"
      >
        Contact us
      </a> */}
      {/* <a
        target="_blank"
        rel="noreferrer"
        href="https://share.hsforms.com/1ya_KqjN3T8-kjcSYSPR3-A5bfzp"
      >
        Subscribe
      </a> */}
    </>
  );
};

export const Copyright = () => {
  return (
    <>
      <a target="_blank" rel="noreferrer" href="https://www.space-whale.com/">
        Copyright © Space Whale. All Rights Reserved.
      </a>
    </>
  );
};

const App = () => {
  return (
    <>
      <div className="viewport">
        {/* <div className="left_line" />
        <img className="ww_logo" src="assets/WW_LogoWhite.png" alt="" /> */}
        <p>
          Take on the role of the last pilgrim of the Wisdom Watchers and fight
          to avenge your fallen comrades in battle. Wisdom Watcher is the first
          clockpunk FPS inVirtual Reality, developed by the Italian game studio
          Space Whale.
        </p>
        <div className="footer2">
          <FooterContent />
          <Copyright />
        </div>
      </div>
    </>
  );
};

export default App;
