import React from "react";
import "./App.css";
import Page1 from "./Pages/Page1";
import Leaderboards from "./Pages/Leaderboards";
import Leaderboards2 from "./Pages/Leaderboards2";
import { useMediaQuery } from "react-responsive";
import { useSnapshot } from "valtio";
import { state } from "./store";
import Footer from "Pages/PageFooter";

const App = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 900px)",
  });
  const snap = useSnapshot(state);

  return (
    <>
      <main>
        {snap.showLeaderboard ? (
          isDesktopOrLaptop ? (
            <>
              <section id="l1">
                <Leaderboards />
              </section>
              <section>
                <Leaderboards2 />
              </section>
            </>
          ) : (
            <section>
              <Leaderboards />
            </section>
          )
        ) : (
          <>
            <section>
              <Page1 />
            </section>
            {/* <section>
              <PageDescrizione />
            </section>
            <section>
              <PageCarousel />
            </section>
            <section>
              <PageSubscribe />
            </section> */}
            <section>
              <Footer />
            </section>
          </>
        )}
      </main>
    </>
  );
};

export default App;
